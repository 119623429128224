// @ts-ignore
import React, {ChangeEvent, useEffect, useState} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {Link} from "react-router-dom";
import {
    Box,
    Button,
    FormControl,
    TextField,
} from "@mui/material";
import {postAddPrinter} from "../../http/db";
import {customFetch} from "../../utils/customFetch";

const Component = () => {
    const [model, setModel] = useState<string>('')
    const [price, setPrice] = useState<number>(0)
    const [purchaseDate, setPurchaseDate] = useState<string>('')

    const handleModelChange = (e: ChangeEvent<HTMLInputElement>) => {
        setModel(e.target.value);
    }

    const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPrice(Number(e.target.value));
    }

    const handlePurchaseDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPurchaseDate(e.target.value);
    }

    const sendData = async ()=>{
        const [year, month, day] = purchaseDate.split('-').map(Number);
        await customFetch({
            ...postAddPrinter,
            body: JSON.stringify({
                model,
                price,
                purchaseDate: new Date(year, month-1, day),
            })
        })
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <CssBaseline/>
            <div>Тут будет страница с формой добавления принтера</div>
            <Box my={2}>
                <FormControl fullWidth>
                    <TextField label='Model' variant='outlined' onChange={handleModelChange} value={model}/>
                </FormControl>
            </Box>
            <Box my={2}>

                <FormControl fullWidth>
                    <TextField label='Price' variant='outlined' type='number' onChange={handlePriceChange}
                               value-={price}/>
                </FormControl>
            </Box>
            <Box my={2}>
                <FormControl fullWidth>
                    Purchase Date
                    <TextField variant='outlined' type='date' onChange={handlePurchaseDateChange} value={purchaseDate}/>
                </FormControl>
            </Box>
            <Button variant="contained" onClick={sendData}>Сохранить</Button>
            <div><Link to={'/statistics'}>Ссылка на статистику</Link></div>
            <div><Link to={'/add-record'}>Ссылка на форму записи</Link></div>
            <div><Link to={'/add-file'}>Ссылка на форму детали</Link></div>
            <div><Link to={'/'}>Ссылка на главную</Link></div>
        </Box>
    );
}

export const AddPrinter = React.memo(Component);
