import React, {useEffect, useState} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {IFullRecord} from "../../types/domain";
import {customFetch} from "../../utils/customFetch";
import {getRecords} from "../../http/db";
import {processApiResponse} from "../../utils/processApiResponse";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

const Component = () => {
    const [records, setRecords] = useState<Array<IFullRecord>>([])

    useEffect(() => {
        (async () => {
            const res = await customFetch(getRecords)
            const data = await processApiResponse(res)
            setRecords(data?.records)
        })()
    }, [])
    return (
        <div>
            <CssBaseline/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={9}>Printers</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Record Id</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Printer Id</TableCell>
                        <TableCell>Printer Model</TableCell>
                        <TableCell>Printer Price</TableCell>
                        <TableCell>Printer Purchase Date</TableCell>
                        <TableCell>Detail Id</TableCell>
                        <TableCell>Detail Name</TableCell>
                        <TableCell>Detail Print Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((record) => <TableRow key={record.id} sx={{
                        backgroundColor: record.id % 2 === 0 ? 'white' : 'lightgray'
                    }}>
                        <TableCell>{record.id}</TableCell>
                        <TableCell>{new Date(record.date).toLocaleDateString('ru-RU', {
                            year: '2-digit',
                            day: '2-digit',
                            month: '2-digit',
                        })}</TableCell>
                        <TableCell>{record.printerId}</TableCell>
                        <TableCell>{record.printerModel}</TableCell>
                        <TableCell>{record.printerPrice}</TableCell>
                        <TableCell>{new Date(record.printerPurchaseDate).toLocaleDateString('ru-RU', {
                            year: '2-digit',
                            day: '2-digit',
                            month: '2-digit',
                        })}</TableCell>
                        <TableCell>{record.detailId}</TableCell>
                        <TableCell>{record.detailName}</TableCell>
                        <TableCell>{record.detailPrintTime}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </div>
    );
}

export const RecordsTab = React.memo(Component);
