import React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {Link} from "react-router-dom";

const Component = () => {
    return (
        <div>
            <CssBaseline/>
            <div>Тут будет главная страница</div>
            <div><Link to={'/statistics'}>Ссылка на статистику</Link></div>
            <div><Link to={'/add-record'}>Ссылка на форму записи</Link></div>
            <div><Link to={'/add-printer'}>Ссылка на форму принтера</Link></div>
            <div><Link to={'/add-file'}>Ссылка на форму файлов</Link></div>

        </div>
    );
}

export const MainPage = React.memo(Component);
