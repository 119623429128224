import {dbScope} from "./base";
import {HttpMethods} from "../types/http";

const ADD_RECORD_URL = `${dbScope}/record/add`;
const ADD_PRINTER_URL = `${dbScope}/printer/add`
const ADD_FILE_URL = `${dbScope}/file/add`
const GET_RECORDS_URL = `${dbScope}/records`
const GET_PRINTERS_URL = `${dbScope}/printers`
const GET_FILES_URL = `${dbScope}/files`
const GET_PRINTERS_DATA_URL = `${dbScope}/printers/data`

export const postAddRecord = {
    url: ADD_RECORD_URL,
    method: HttpMethods.Post,
    headers: {'Content-Type': 'application/json'},
}

export const postAddPrinter = {
    url: ADD_PRINTER_URL,
    method: HttpMethods.Post,
    headers: {'Content-Type': 'application/json'},
}

export const postAddFile = {
    url: ADD_FILE_URL,
    method: HttpMethods.Post,
    headers: {'Content-Type': 'application/json'},
}
export const getRecords = {
    url: GET_RECORDS_URL,
    method: HttpMethods.Get,
    headers: {'Content-Type': 'application/json'},
}
export const getPrinters = {
    url: GET_PRINTERS_URL,
    method: HttpMethods.Get,
    headers: {'Content-Type': 'application/json'},
}

export const getPrinterData = {
    url: GET_PRINTERS_DATA_URL,
    method: HttpMethods.Get,
    headers: {'Content-Type': 'application/json'},
}

export const getFiles = {
    url: GET_FILES_URL,
    method: HttpMethods.Get,
    headers: {'Content-Type': 'application/json'},
}
