import React, {useEffect, useState} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {Link} from "react-router-dom";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import {processApiResponse} from "../../utils/processApiResponse";
import {IPrinter, IFile} from "../../types/domain";
import {customFetch} from "../../utils/customFetch";
import {getFiles, getPrinters, postAddRecord} from "../../http/db";

const Component = () => {
    const [printerList, setPrinterList] = useState<Array<IPrinter>>([])
    const [printer, setPrinter] = useState<string>('')
    const [detailList, setDetailList] = useState<Array<IFile>>([])
    const [detail, setDetail] = useState<string>('')
    const [currentTime, setCurrentTime] = useState<Date>(new Date())

    const handlePrinterChange = (e: SelectChangeEvent) => {
        setPrinter(e.target.value);
    }

    const handleDetailChange = (e: SelectChangeEvent) => {
        setDetail(e.target.value);
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date())
        }, 500)
        return () => {
            clearInterval(timer);
        }
    }, [])

    useEffect(() => {
        (async () => {
            const response = await customFetch(getPrinters)
            const data = await processApiResponse(response)
            setPrinterList(data?.printers);
        })();
        (async () => {
            const response = await customFetch(getFiles)
            const data = await processApiResponse(response)
            setDetailList(data?.files);
        })()
    }, [])

    const sendData = async ()=>{
        await customFetch({
            ...postAddRecord,
            body: JSON.stringify({
                printer,
                detail,
                date: currentTime,
            })
        })
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <CssBaseline/>
            <div>Тут будет страница с формой добавления записи о печати</div>
            <Box my={2}>
                <FormControl fullWidth>
                    <InputLabel id="printerLabel">Printer</InputLabel>
                    <Select
                        labelId="printerLabel"
                        value={printer}
                        label="Printer"
                        onChange={handlePrinterChange}
                    >
                        {printerList.map((printer) =>
                            <MenuItem value={printer.id} key={printer.id}>
                                {`${printer.id}. ${printer.model} - ${new Date(printer.purchaseDate).toLocaleDateString('ru-RU', {
                                    year: '2-digit',
                                    day: '2-digit',
                                    month: '2-digit',
                                })}`}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel id="fileLabel">File</InputLabel>
                    <Select
                        labelId="fileLabel"
                        value={detail}
                        label="Files"
                        onChange={handleDetailChange}
                    >
                        {detailList.map((detail) =>
                            <MenuItem value={detail.id} key={detail.id}>
                                {`${detail.id}. ${detail.name} - ${detail.printTime}`}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
            <Box my={2}>
                <Typography variant='h4'>
                    Время запуска:
                </Typography>
                <Typography variant='h5'>
                    {currentTime?.toLocaleString('ru-RU')}
                </Typography>
            </Box>
            <Button variant="contained" onClick={sendData}>Сохранить</Button>
            <div><Link to={'/statistics'}>Ссылка на статистику</Link></div>
            <div><Link to={'/add-printer'}>Ссылка на форму принтера</Link></div>
            <div><Link to={'/add-file'}>Ссылка на форму детали</Link></div>
            <div><Link to={'/'}>Ссылка на главную</Link></div>
        </Box>
    );
}

export const AddRecord = React.memo(Component);
