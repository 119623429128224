import React, {useEffect, useState} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {IFile} from "../../types/domain";
import {customFetch} from "../../utils/customFetch";
import {getFiles} from "../../http/db";
import {processApiResponse} from "../../utils/processApiResponse";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

const Component = () => {
    const [details, setDetails] = useState<Array<IFile>>([])

    useEffect(() => {
        (async () => {
            const res = await customFetch(getFiles)
            const data = await processApiResponse(res)
            setDetails(data?.files)
        })()
    }, [])
    return (
        <div>
            <CssBaseline/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Print time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {details.map((detail) => <TableRow key={detail.id} sx={{
                        backgroundColor: detail.id % 2 === 0 ? 'white' : 'lightgray'
                    }}>
                        <TableCell>{detail.id}</TableCell>
                        <TableCell>{detail.name}</TableCell>
                        <TableCell>{detail.printTime}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </div>
    );
}

export const DetailsTab = React.memo(Component);
