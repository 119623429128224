import {IApiResponse} from "../types/http";

export const processApiResponse = async (response: Response)=>{
    const json: IApiResponse = await response.json();
    if(!json.success){
        console.error(json.errors);
        return;
    }
    return json.result;
}
