// @ts-ignore
import React, {ChangeEvent, useEffect, useState} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {Link} from "react-router-dom";
import {
    Box,
    Button,
    FormControl,
    TextField,
} from "@mui/material";
import {postAddFile} from "../../http/db";
import {customFetch} from "../../utils/customFetch";

const Component = () => {
    const [name, setName] = useState<string>('')
    const [printTime, setPrintTime] = useState<number>(0)

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const handlePrintTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPrintTime(Number(e.target.value));
    }


    const sendData = async () => {
        await customFetch({
            ...postAddFile,
            body: JSON.stringify({
                name,
                printTime
            })
        })
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <CssBaseline/>
            <div>Тут будет страница с формой добавления файла</div>
            <Box my={2}>
                <FormControl fullWidth>
                    <TextField label='Name' variant='outlined' onChange={handleNameChange} value={name}/>
                </FormControl>
            </Box>
            <Box my={2}>
                <FormControl fullWidth>
                    <TextField label='PrintTime' variant='outlined' type='number' onChange={handlePrintTimeChange}
                               value-={printTime}/>
                </FormControl>
            </Box>

            <Button variant="contained" onClick={sendData}>Сохранить</Button>
            <div><Link to={'/statistics'}>Ссылка на статистику</Link></div>
            <div><Link to={'/add-record'}>Ссылка на форму записи</Link></div>
            <div><Link to={'/add-printer'}>Ссылка на форму принтера</Link></div>
            <div><Link to={'/'}>Ссылка на главную</Link></div>
        </Box>
    );
}

export const AddFile = React.memo(Component);
