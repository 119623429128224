import React, {useEffect, useState} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {IPrinter} from "../../types/domain";
import {customFetch} from "../../utils/customFetch";
import {getPrinterData} from "../../http/db";
import {processApiResponse} from "../../utils/processApiResponse";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

const Component = () => {
    const [printers, setPrinters] = useState<Array<IPrinter>>([])

    useEffect(() => {
        (async () => {
            const res = await customFetch(getPrinterData)
            const data = await processApiResponse(res)
            setPrinters(data?.printers)
        })()
    }, [])
    return (
        <div>
            <CssBaseline/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={4}>Printers</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Purchase Date</TableCell>
                        <TableCell>Runs</TableCell>
                        <TableCell>Total Print Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {printers.map((printer) => <TableRow key={printer.id} sx={{
                        backgroundColor: printer.id % 2 === 0 ? 'white' : 'lightgray'
                    }}>
                        <TableCell>{printer.id}</TableCell>
                        <TableCell>{printer.model}</TableCell>
                        <TableCell>{printer.price}</TableCell>
                        <TableCell>{new Date(printer.purchaseDate).toLocaleDateString('ru-RU', {
                            year: '2-digit',
                            day: '2-digit',
                            month: '2-digit',
                        })}</TableCell>
                        <TableCell>{printer.runs || 0}</TableCell>
                        <TableCell>{printer.totalPrintTime || 0}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </div>
    );
}

export const PrintersTab = React.memo(Component);
