import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import {AddRecord} from "./AddRecord/AddRecord";
import {AddFile} from "./AddFile/AddFile";
import {Statistics} from "./Stats/Statistics";
import {MainPage} from "./MainPage/MainPage";
import {AddPrinter} from "./AddPrinter/AddPrinter";
// import {ProfileLayout} from "./Profile/ProfileLayout";
// import {PoolSpaceLayout} from "./PoolSpace/Layout";
const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<MainPage/>}/>
                <Route path='/add-record' element={<AddRecord/>}/>
                <Route path='/add-printer' element={<AddPrinter/>}/>
                <Route path='/add-file' element={<AddFile/>}/>
                <Route path="/statistics" element={<Statistics />}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
