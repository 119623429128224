import React, {useState} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import {Link} from "react-router-dom";
import {Box, Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {RecordsTab} from "./RecordsTab";
import {PrintersTab} from "./PrintersTab";
import {DetailsTab} from "./DetailsTab";

const Component = () => {
    const [tabIndex, setTabIndex] = useState<string>("1")

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue);
    };

    return (
        <div>
            <CssBaseline/>
            <TabContext value={tabIndex}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Records" value="1"/>
                        <Tab label="Printers" value="2"/>
                        <Tab label="Details" value="3"/>
                    </Tabs>
                </Box>
                <TabPanel value="1">
                    <RecordsTab/>
                </TabPanel>
                <TabPanel value="2">
                    <PrintersTab/>
                </TabPanel>
                <TabPanel value="3">
                    <DetailsTab/>
                </TabPanel>
            </TabContext>
            <div><Link to={'/add-record'}>Ссылка на форму записи</Link></div>
            <div><Link to={'/add-printer'}>Ссылка на форму принтера</Link></div>
            <div><Link to={'/add-file'}>Ссылка на форму детали</Link></div>
            <div><Link to={'/'}>Ссылка на главную</Link></div>
        </div>
    );
}

export const Statistics = React.memo(Component);
